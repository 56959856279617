import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";

export default class MyChart extends PureComponent {
  render() {
    const { data } = this.props;

    const colors = ["#fa4141", "#3737fa", "#5fcf68"];
    // const colors = ["#db5870", "#1e8fe0", "#3baf89"];
    // const hovers = ["#1e8fe0", "#0e5d95", "#187455"];

    return (
      <ResponsiveContainer
        width="100%"
        height="68%"
        minWidth={100}
        minHeight={200}
      >
        <BarChart width={200} height={300} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Games" activeBar={<Rectangle fill="#000000" />}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
