import { useState } from "react";
import "./LoginModal.css";
import { TextInput } from "@gravity-ui/uikit";

const LoginModal = ({ isOpen, onClose }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // тут отправка данных
    console.log(username);
    console.log(password);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Login</h2>

        <form onSubmit={handleSubmit}>
          <TextInput
            placeholder="Name"
            pin="round-brick"
            type="text"
            size="s"
            autoFocus="true"
            value={username}
            onUpdate={setUsername}
          />

          <TextInput
            size="s"
            type="password"
            placeholder="Password"
            value={password}
            onUpdate={setPassword}
          />

          <div className="modal-actions">
            <button type="submit" className="history">
              Login
            </button>
            <button className="history" onClick={onClose}>
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginModal;
