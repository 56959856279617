import { useEffect, useState } from "react";
import ThemeToggle from "./ThemeToggle";
import Board from "./Board";
import Statictics from "./Statictics";
import calculateWinner from "./calculateWinner";
import MyChart from "./BarChart";
import github from "./github.png";
import LoginModal from "./LoginModal";
import { ThemeProvider } from "@gravity-ui/uikit";

const baseStats = {
  X: 0,
  O: 0,
  Total: 0,
};

export default function Game() {
  const [history, setHistory] = useState(() => {
    const savedHistory = localStorage.getItem("history");
    return savedHistory ? JSON.parse(savedHistory) : [Array(9).fill(null)];
  });

  const [currentMove, setCurrentMove] = useState(0);

  const [order, setOrder] = useState(() => {
    return localStorage.getItem("order") ? true : false;
  });

  const [stats, setStats] = useState(() => {
    const savedStats = localStorage.getItem("stats");
    return savedStats ? JSON.parse(savedStats) : baseStats;
  });

  const [showChart, setShowChart] = useState(() => {
    return localStorage.getItem("showChart") ? true : false;
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem("theme") || "light"; // get from local storage
  });

  const xIsNext = currentMove % 2 === 0; // четный => X
  const currentSquares = history[currentMove];

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    localStorage.setItem("history", JSON.stringify(history));
    localStorage.setItem("order", order);
    localStorage.setItem("showChart", showChart);
  }, [history, order, showChart]);

  function handlePlay(nextSquares) {
    const nextHistory = [...history.slice(0, currentMove + 1), nextSquares];

    setHistory(nextHistory);
    setCurrentMove(nextHistory.length - 1);
  }

  function jumpTo(nextMove) {
    setCurrentMove(nextMove);
  }

  const moves = history.map((squares, move) => {
    let description;
    if (order) {
      move = history.length - move - 1;
    }
    if (move > 0) {
      description = "Go to move #" + move;
      return (
        <button
          className="history-button history"
          onClick={() => jumpTo(move)}
          key={move}
        >
          {description}
        </button>
      );
    } else {
      return null;
    }
  });

  function sortHistoryList() {
    setOrder(!order);
  }

  function jumpToZero() {
    if (currentMove === 0) {
      return;
    }
    jumpTo(0);

    //update total games
    const nextTotalGames = stats.Total + 1;
    setStats((prevStats) => {
      const newStats = { ...prevStats, Total: nextTotalGames };
      localStorage.setItem("stats", JSON.stringify(newStats));
      return newStats;
    });
  }

  useEffect(() => {
    let winner = calculateWinner(currentSquares);
    if (winner) {
      setStats((prevStats) => {
        const newStats = { ...prevStats };
        ++newStats[winner.win];
        localStorage.setItem("stats", JSON.stringify(newStats));
        return newStats;
      });
    }
  }, [currentSquares]);

  function ResetStats() {
    setStats(baseStats);
    localStorage.setItem("stats", JSON.stringify(baseStats));
  }

  stats.Total =
    stats.Total < stats.X + stats.O ? stats.X + stats.O : stats.Total;

  const myData = [
    { name: "X", Games: stats.X },
    { name: "O", Games: stats.O },
    { name: "Draw", Games: stats.Total - stats.X - stats.O },
  ];

  return (
    <ThemeProvider theme={theme}>
      <div className="game">
        <div className="game-board">
          <button className="history login" onClick={openModal}>
            Login
          </button>
          <Board
            xIsNext={xIsNext}
            squares={currentSquares}
            onPlay={handlePlay}
          />
          <button className="history restart" onClick={jumpToZero}>
            Restart
          </button>
        </div>
        <div className="game-info">
          <div className="panel">
            <button className="history" onClick={sortHistoryList}>
              {order ? "↑" : "↓"}
            </button>
            <button
              className="history"
              onClick={(showChart) => {
                setShowChart(!showChart);
              }}
            >
              •
            </button>
            <ThemeToggle theme={theme} setTheme={setTheme} />
          </div>
          <div className="history-container">{moves}</div>
        </div>
        <div className="game-stats">
          <div className="stats-table">
            <Statictics stats={stats} squares={currentSquares} />
            <button className="history reset" onClick={ResetStats}>
              Reset
            </button>
          </div>
          {showChart && <MyChart data={myData} />}
        </div>
      </div>
      <div class="credits">
        Credits
        <a href="https://github.com/Bar1o" target="_blank" rel="noreferrer">
          <img className="github-icon" src={github} alt="GitHub" />
          Bar1o
        </a>
        <div>2024</div>
      </div>
      <LoginModal isOpen={isModalOpen} onClose={closeModal} />
    </ThemeProvider>
  );
}
